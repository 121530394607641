import React from "react"
import '../styles/global.scss'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/Title/"
import GoogleMap from '../components/map'

import CellPhone from "../icons/cell_phone.svg"
import Telephone from "../icons/telephone.svg"
import Email from "../icons/email.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Biuro Rachunkowe Jolanta Saska - Oferta | Kraków" />

    <section className="section has-bg-color">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds">
            <div className="content">
              <h1 className='title is-1 is-spaced'><Title text="Oferta" /></h1>
              <p>Nasza oferta obejmuje kompleksową obsługę firm w następujących zakresach:</p>
        			<h2>Usługi księgowe i rachunkowe:</h2>
        			<ul>
        				<li>Prowadzenie podatkowej księgi przychodów i rozchodów, księgi handlowej, ryczałtu</li>
        				<li>Prowadzenie rejestrów VAT zakupu i sprzedaży</li>
        				<li>Prowadzenie ewidencji środków trwałych oraz ewidencji wyposażenia</li>
        				<li>Sporządzanie deklaracji miesięcznych</li>
        				<li>Rozliczenia ZUS właściciela firmy</li>
        				<li>Sporządzanie i dostarczanie do US pism, korekt i deklaracji miesięcznych</li>
        				<li>Rozliczenie roczne właściciela firmy – tylko z tytułu prowadzonej działalności</li>
        				<li>Reprezentowanie podatnika przed US, UKS, ZUS, GUS</li>
        				<li>Zmiany danych w US i ZUS</li>
        				<li>Wyprowadzanie zaległości</li>
        				<li>Rozliczanie delegacji krajowych i zagranicznych</li>
        				<li>Informacjami dotyczącymi wielkości podatków i składek do zapłaty</li>
        				<li>Prowadzenie spraw przed US,UKS,ZUS w imieniu podatnika – w przypadku upoważnienia</li>
        				<li>Analizy i zestawienia ekonomiczne i finansowe</li>
        				<li>Konsultacje, porady księgowe w siedzibie Biura Rachunkowego</li>
        				<li>Usługi księgowe możemy wykonywać w siedzibie firmy klienta</li>
        			</ul>
        			<h2>Obsługa kadrowo-płacowa:</h2>
        			<ul>
        			 	<li>Naliczanie wynagrodzeń oraz sporządzanie list płac zgodnie z obowiązującymi przepisami na podstawie otrzymanych danych</li>
        			 	<li>Sporządzanie oraz przekazywanie rozliczeń i deklaracji do US i ZUS</li>
        			 	<li>Prowadzenie ewidencji wynagrodzeń poszczególnych pracowników</li>
        			 	<li>Sporządzanie rozliczeń rocznych pracowników PIT-11, PIT-8, PIT-40</li>
        			 	<li>Wystawianie świadectw pracy</li>
        			 	<li>Wystawianie zaświadczeń o osiągniętych dochodach</li>
        			 	<li>Sporządzanie raportów i analiz dotyczących wynagrodzeń</li>
        			 </ul>
        			 <h2>Sporządzanie zeznań rocznych:</h2>
        			 <ul>
        			 	<li>sporządzanie rocznych rozliczeń podatkowych PIT-36, PIT-36L, PIT-37, PIT-38</li>
        			 	<li>sporządzanie rozliczeń dochodów uzyskiwanych z najmu oraz rozliczanych na zasadach ryczałtu</li>
        			 	<li>rozliczanie dochodów z papierów wartościowych</li>
        			 	<li>rozliczanie dochodów ze zbycia nieruchomości i praw majątkowych</li>
        			 	<li>rozliczanie dochodów zagranicznych </li>
        			 	<li>rozliczanie dochodów  uzyskiwanych przez małoletnie dzieci</li>
        			 </ul>
        			 <p>Nasze ceny są konkurencyjne. Nie stosujemy żadnych ukrytych dopłat i nie naliczamy żadnych dodatkowych opłat np. za konsultacje, sporządzanie deklaracji do US, ZUS. Nasza cena już to wszystko zawiera.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="contact" className="section contact-section">
      <div className="contact-section__half"></div>
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            <div className="contact-section__content has-text-white">
              <h3 className='title is-1 is-spaced has-text-white'><Title text="Kontakt" /></h3>
              <div className="has-text-white contact-info">
                Jolanta Saska - Biuro Rachunkowe <br />
                ul. Vetulaniego 1A / pok. 210 <br />
                31-227 Kraków <br />
                NIP: 945-13-43-698 <br/>
              </div>
              <div className="columns is-multiline">
                <div className="column is-6">
                  <div className="contact-item">
                    <CellPhone width="32px"  height="32px" />
                    <a href="tel:+48502276292">502 276 292</a>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="contact-item">
                    <Telephone width="32px"  height="32px" />
                    <a href="tel:+48124202130">12 420 21 30</a>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="contact-item">
                    <Email width="32px"  height="32px" />
                    <a href="mailto:jolanta.saska@gmail.com">jolanta.saska@gmail.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-7">
            <div className="map-container">
              <GoogleMap />
            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default IndexPage
